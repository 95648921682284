import types from './types';
import { defaultState } from './state';
import { SURVEY_DISMISSED_TEST_IDS } from '~/core/utils/constants';

export default {
  [types.SET_BASIC_INFO](state, payload) {
    state.exam = payload;
  },

  [types.SET_EXTEND_TIME_RATIO](state, payload) {
    state.extendTimeRatio = payload;
  },

  [types.RESET_STATE](state) {
    Object.assign(state, defaultState());
  },

  [types.SET_DELAY](state, payload) {
    state.delay = payload;
  },

  [types.SET_WITH_SOLUTION](state, payload) {
    state.withSolution = payload;
  },

  [types.SET_SURVEY_DISMISSED_TEST_ID](state, payload) {
    state.surveyDismissedTestIds.push(payload);
    localStorage.setItem(SURVEY_DISMISSED_TEST_IDS, JSON.stringify(state.surveyDismissedTestIds));
  },
};
