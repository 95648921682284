import types from './types';

export default {
  async fetchExams() {
    try {
      const { data } = await this.$api.exam.fetchExams();
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async initialExam({ commit }, slug) {
    try {
      const { data: exam } = await this.$api.exam.fetchExam(slug);
      const extendTimeRation = this.$services.exam.convertServerExtendTimeRationToNumber(exam);
      commit(types.SET_BASIC_INFO, exam);
      commit(types.SET_EXTEND_TIME_RATIO, extendTimeRation);
      commit(types.SET_WITH_SOLUTION, exam.with_solution);
      return exam;
    } catch (err) {
      throw new Error(err);
    }
  },

  setExtendTimeRatio({ commit }, rate) {
    commit(types.SET_EXTEND_TIME_RATIO, rate);
  },

  resetExtendTimeRatio({ commit, getters }) {
    const exam = getters.exam;
    const extendTimeRation = this.$services.exam.convertServerExtendTimeRationToNumber(exam);
    commit(types.SET_EXTEND_TIME_RATIO, extendTimeRation);
  },

  async fetchStatisticReports(_, { id, type }) {
    try {
      const { data } = await this.$api.exam.fetchStatisticReports(id, { type });
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  resetState({ commit }) {
    commit(types.RESET_STATE);
  },

  async startTest({ commit, getters }, { id, extendRatio = 1, with_solution = false }) {
    const delay = getters.delay;

    const withTime = extendRatio !== Infinity;
    const withTime1 = withTime && extendRatio === 1;
    const withTime1_25 = withTime && extendRatio === 1.25;
    const withTime1_5 = withTime && extendRatio === 1.5;
    const withTime2 = withTime && extendRatio === 2;

    let apiPayload = {
      with_time: withTime,
      with_time_1: withTime1,
      with_time_1_25: withTime1_25,
      with_time_1_5: withTime1_5,
      with_time_2: withTime2,
      with_solution,
    };
    if (delay) {
      apiPayload = { ...apiPayload, metadata: { delay } };
    }
    try {
      const { data } = await this.$api.exam.startTest(id, apiPayload);
      commit(types.SET_BASIC_INFO, { ...getters.exam, test_id: data.test_id });
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchSections(_, testId) {
    try {
      const { data } = await this.$api.exam.fetchSections(testId);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchSectionDetailStatistic(_, { examId, testId, sectionId }) {
    const { data } = await this.$api.exam.fetchSectionDetailStatistic(examId, testId, sectionId);
    return data;
  },

  fetchQuestionsStatistic(_, { id, testId, sectionId, params }) {
    return this.$api.exam.fetchQuestionsStatistic(id, testId, sectionId, params);
  },

  async fetchSectionsStatistics(_, { id, testId }) {
    try {
      const { data } = await this.$api.exam.fetchSectionsStatistics(id, testId);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchPassagesAndQuestions(_, paramIds) {
    try {
      const { data } = await this.$api.quiz.fetchPassagesAndQuestions(paramIds);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchHighlights(_, highlighParams) {
    try {
      const { data } = await this.$api.exam.fetchHighlights(highlighParams);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchTest(_, testId) {
    const { data } = await this.$api.qBank.fetchTestDetail(testId);
    return data;
  },

  async resetTest(_, testId) {
    try {
      const { data } = await this.$api.exam.resetTest(testId);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchBreakdownBuySubject(_, testId) {
    try {
      const { data } = await this.$api.qBank.fetchBreakdownBuySubject(testId);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchBreakdownBuyDifficulty(_, testId) {
    try {
      const { data } = await this.$api.qBank.fetchBreakdownBuyDifficulty(testId);
      const mappedBreakdown = data.map((item) => ({
        ...item,
        name: item.difficulty,
      }));
      return mappedBreakdown;
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchTestAnalysis(_, testId) {
    try {
      const { data } = await this.$api.quiz.fetchAnalysis(testId);
      return data;
    } catch {}
  },

  setDelay({ commit }, delay) {
    commit(types.SET_DELAY, delay);
  },

  async fetchExamsByField(_, fieldId) {
    const { data } = await this.$api.exam.fetchExamsByField(fieldId);
    return data;
  },

  setWithSolution({ commit }, withSolution) {
    commit(types.SET_WITH_SOLUTION, withSolution);
  },

  setSurveyDismissedTestIds({ commit }, testId) {
    commit(types.SET_SURVEY_DISMISSED_TEST_ID, testId);
  },
};
