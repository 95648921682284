import { SURVEY_DISMISSED_TEST_IDS } from '~/core/utils/constants';

export const defaultState = () => ({
  exam: null,
  extendTimeRatio: 1,
  delay: null,
  withSolution: false,
  surveyDismissedTestIds: JSON.parse(localStorage.getItem(SURVEY_DISMISSED_TEST_IDS)) || [],
});

export default () => defaultState();
